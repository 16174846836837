<script setup lang="ts"></script>

<template>
    <svg
        width="38"
        height="36"
        viewBox="0 0 38 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.1278 34.6552L36.929 21.7806L30.1303 0.948242H8.12521L1.32422 21.7806L19.1278 34.6552Z"
            fill="#4F1C19"
        />
        <path
            d="M30.7651 0.0695801H7.48713L0.300781 22.1131L19.132 35.734L37.9655 22.1131L30.7651 0.0695801ZM4.01891 20.9089L9.78994 3.24058H28.4623L34.2333 20.9089L19.125 31.8354L4.01891 20.9089Z"
            fill="url(#paint0_radial_1674_36818)"
        />
        <g style="mix-blend-mode: multiply" opacity="0.2">
            <path
                d="M9.78906 3.2312L19.1264 31.8353L28.4614 3.2312H9.78906Z"
                fill="#34010B"
            />
        </g>
        <path
            d="M30.7651 0.0695801H7.4871L5.82422 5.18409C6.90013 5.23737 7.9709 5.36633 9.02853 5.57001L9.78991 3.24523H28.4623L34.2333 20.9135L32.0426 22.499C32.5381 23.4561 32.9734 24.443 33.3458 25.4538L37.9584 22.1178L30.7651 0.0695801Z"
            fill="url(#paint1_radial_1674_36818)"
        />
        <path
            style="mix-blend-mode: screen"
            d="M30.7651 0.0695801H7.4871L5.82422 5.18409C6.90013 5.23737 7.9709 5.36633 9.02853 5.57001L9.78991 3.24523H28.4623L34.2333 20.9135L32.0426 22.499C32.5381 23.4561 32.9734 24.443 33.3458 25.4538L37.9584 22.1178L30.7651 0.0695801Z"
            fill="url(#paint2_linear_1674_36818)"
        />
        <path
            style="mix-blend-mode: screen"
            d="M29.4946 28.2389C29.3903 27.0599 29.2093 25.8889 28.9528 24.7331L19.1436 31.8353L4.03759 20.9089L8.16209 8.2063C6.99987 8.23377 5.84239 8.36369 4.70321 8.59454L0.300781 22.1131L19.132 35.734L29.4946 28.2389Z"
            fill="url(#paint3_linear_1674_36818)"
        />
        <path
            d="M4.01953 20.9088L5.51192 20.5764L10.7131 4.64467H27.5404L32.7416 20.5764L34.234 20.9088L28.4629 3.2312H9.79056L4.01953 20.9088Z"
            fill="#8F4423"
        />
        <g style="mix-blend-mode: multiply" opacity="0.2">
            <path
                d="M5.51172 20.5764L8.42176 13.1371C10.1454 8.73628 14.403 6.82764 19.1464 6.82764C23.9132 6.82764 28.1848 8.76185 29.8897 13.1906L32.7367 20.5694L27.5355 4.6377H10.7129L5.51172 20.5764Z"
                fill="#AA3708"
            />
        </g>
        <path
            d="M4.01953 20.9089L5.51192 20.5764L19.1279 30.4219L32.7416 20.5764L34.234 20.9089L19.1279 31.8353L4.01953 20.9089Z"
            fill="url(#paint4_linear_1674_36818)"
        />
        <g style="mix-blend-mode: multiply" opacity="0.6">
            <path
                d="M5.51172 20.5765L10.8507 6.93701C10.977 6.61577 11.1977 6.3399 11.4839 6.1454C11.7701 5.95089 12.1086 5.8468 12.4552 5.84669H25.875C26.2231 5.84673 26.563 5.95185 26.8499 6.14817C27.1368 6.34448 27.3571 6.62278 27.4818 6.94631L32.7414 20.5765L27.5402 4.64478H10.7129L5.51172 20.5765Z"
                fill="#AA3708"
            />
        </g>
        <path
            d="M9.78906 3.2312L10.7116 4.64467H27.5389L28.4614 3.2312H9.78906Z"
            fill="#572516"
        />
        <g style="mix-blend-mode: multiply" opacity="0.35">
            <path
                d="M2.86984 14.2112L1.06683 20.9344C1.00756 21.2472 1.03324 21.57 1.14122 21.8696C1.2492 22.1692 1.43557 22.4346 1.68107 22.6385L19.1203 35.734L0.300781 22.1131L2.86984 14.2112Z"
                fill="#8F4423"
            />
        </g>
        <g style="mix-blend-mode: multiply" opacity="0.6">
            <path
                d="M5.51172 20.5764L18.4995 29.285C18.6885 29.3979 18.9049 29.4576 19.1254 29.4576C19.3459 29.4576 19.5622 29.3979 19.7513 29.285L32.7414 20.5764L19.1254 30.4219L5.51172 20.5764Z"
                fill="#AA3708"
            />
        </g>
        <g style="mix-blend-mode: multiply" opacity="0.35">
            <path
                d="M19.125 35.7341L35.8939 22.8525C37.0617 21.9854 37.3209 21.7994 36.7931 20.1395L33.2898 7.83911L37.9608 22.1249L19.125 35.7341Z"
                fill="#8F4423"
            />
        </g>
        <g style="mix-blend-mode: multiply" opacity="0.35">
            <path
                d="M3.29688 12.9117L7.22987 1.69692C7.63625 0.555459 7.93052 0.257886 9.14732 0.213715L19.1246 0.0695801H7.48677L3.29688 12.9117Z"
                fill="#8F4423"
            />
        </g>
        <path
            d="M10.8047 25.8166L19.1284 31.8354L30.3645 23.708L19.1284 32.3469L10.8047 25.8166Z"
            fill="#FFDFE7"
        />
        <path
            d="M18.4961 0.0766602H30.7668L33.2962 7.82747L30.2974 0.506745L18.4961 0.0766602Z"
            fill="#FFDFE7"
        />
        <g style="mix-blend-mode: screen">
            <path
                d="M23.378 0.0695801C23.2752 1.07621 23.7166 2.19908 24.5667 3.22431H28.4623L29.4269 6.17677C30.5211 6.47015 31.6717 6.48613 32.7737 6.22327L30.7652 0.0695801H23.378Z"
                fill="#F2F8F6"
            />
        </g>
        <g style="mix-blend-mode: screen">
            <path
                d="M22.4405 1.83168C21.6277 1.24351 20.6468 1.20399 20.2498 1.74799C19.9672 2.13622 20.0629 2.71742 20.4389 3.2312H23.356C23.3073 2.94811 23.201 2.67793 23.0435 2.43725C22.8861 2.19658 22.6809 1.9905 22.4405 1.83168Z"
                fill="#F2F8F6"
            />
        </g>
        <g style="mix-blend-mode: screen">
            <path
                d="M9.79158 25.0842C9.49731 25.8398 10.0251 26.965 11.1018 27.7414C12.1785 28.5179 13.4139 28.6714 14.0445 28.1599L9.79158 25.0842Z"
                fill="#F2F8F6"
            />
        </g>
        <g style="mix-blend-mode: screen">
            <path
                d="M14.0804 30.2197C13.9005 30.4941 13.4031 30.4894 12.9687 30.2081C12.5343 29.9268 12.3311 29.4758 12.5016 29.2015C12.6721 28.9272 13.1765 28.9341 13.6109 29.2131C14.0453 29.4921 14.2532 29.9454 14.0804 30.2197Z"
                fill="#F2F8F6"
            />
        </g>
        <g style="mix-blend-mode: multiply" opacity="0.6">
            <path
                d="M19.1277 17.2474L10.3672 13.2046V14.3344L19.1277 20.6695L27.8858 14.3344V13.2046L19.1277 17.2474Z"
                fill="#AA3708"
            />
        </g>
        <g style="mix-blend-mode: multiply" opacity="0.6">
            <path
                d="M19.1273 24.3728L8.48438 18.97V20.0975L19.1273 27.7948L29.7678 20.0975V18.97L19.1273 24.3728Z"
                fill="#AA3708"
            />
        </g>
        <path
            d="M19.1277 15.9781L10.3672 11.5122V13.2046L19.1277 19.5397L27.8858 13.2046V11.5122L19.1277 15.9781Z"
            fill="#8F4423"
        />
        <path
            d="M19.1273 23.1036L8.48438 17.2754V18.9702L19.1273 26.6652L29.7678 18.9702V17.2754L19.1273 23.1036Z"
            fill="#8F4423"
        />
        <path
            d="M19.1277 14.2857L11.3037 8.64111L10.3672 11.5122L19.1277 17.8472L27.8858 11.5122L26.9493 8.64111L19.1277 14.2857Z"
            fill="url(#paint5_radial_1674_36818)"
        />
        <path
            d="M19.1273 21.4088L9.42091 14.4089L8.48438 17.2754L19.1273 24.9727L29.7678 17.2754L28.8313 14.4089L19.1273 21.4088Z"
            fill="url(#paint6_radial_1674_36818)"
        />
        <path
            style="mix-blend-mode: screen"
            d="M26.9481 8.64111L22.6602 11.7354C23.4566 12.3259 24.2343 12.9489 24.984 13.6115L27.8847 11.5192L26.9481 8.64111Z"
            fill="url(#paint7_linear_1674_36818)"
        />
        <path
            style="mix-blend-mode: screen"
            d="M27.1094 15.6456C27.7758 16.3415 28.4087 17.0661 29.0081 17.8193L29.7695 17.2683L28.8353 14.4019L27.1094 15.6456Z"
            fill="url(#paint8_linear_1674_36818)"
        />
        <path
            style="mix-blend-mode: screen"
            d="M23.6726 14.5577C22.9959 13.8444 22.2667 13.1823 21.4912 12.577L19.1253 14.2857L11.3014 8.64111L10.3672 11.5122L19.1277 17.8472L23.6726 14.5577Z"
            fill="url(#paint9_linear_1674_36818)"
        />
        <path
            style="mix-blend-mode: screen"
            d="M19.1288 24.9727L27.0041 19.277C26.5642 18.4209 26.0656 17.596 25.5117 16.8081L19.1288 21.4112L9.42244 14.4089L8.47656 17.2731L19.1288 24.9727Z"
            fill="url(#paint10_linear_1674_36818)"
        />
        <path
            d="M10.3672 11.5122L19.1277 17.8472V19.5397L10.3672 13.2046V11.5122Z"
            fill="#572516"
        />
        <path
            d="M8.48438 17.2754L19.1273 24.9727V26.6652L8.48438 18.9702V17.2754Z"
            fill="#572516"
        />
        <path
            d="M12.6406 9.60571L19.1287 14.7876L24.7596 10.2218L19.1287 14.2855L12.6406 9.60571Z"
            fill="#FFDFE7"
        />
        <path
            d="M13.0039 16.9916L19.1253 22.1108L26.5942 16.0222L19.1276 21.4087L13.0039 16.9916Z"
            fill="#FFDFE7"
        />
        <g style="mix-blend-mode: multiply" opacity="0.35">
            <path
                d="M11.3037 8.64111L10.9861 10.5312C10.9395 10.768 10.9638 11.0132 11.056 11.2363C11.1482 11.4595 11.3042 11.6508 11.5046 11.7865L19.1277 17.8426L10.3672 11.5122L11.3037 8.64111Z"
                fill="#8F4423"
            />
        </g>
        <g style="mix-blend-mode: multiply" opacity="0.35">
            <path
                d="M9.42244 14.4089L9.12116 16.3013C9.08146 16.6988 9.16087 17.1777 9.47149 17.4311L19.1288 24.9727L8.47656 17.2731L9.42244 14.4089Z"
                fill="#8F4423"
            />
        </g>
        <g style="mix-blend-mode: multiply" opacity="0.35">
            <path
                d="M24.4375 14.0183L26.6329 12.0282C26.8628 11.821 27.0395 11.562 27.148 11.2727C27.2565 10.9835 27.2937 10.6725 27.2565 10.366L27.107 9.14087L27.8847 11.5238L24.4375 14.0183Z"
                fill="#8F4423"
            />
        </g>
        <g style="mix-blend-mode: multiply" opacity="0.35">
            <path
                d="M24.4375 21.1297L28.7535 17.4379C28.9349 17.299 29.0756 17.1142 29.161 16.9029C29.2464 16.6915 29.2735 16.4613 29.2393 16.236L29.0291 15.0178L29.7671 17.2752L24.4375 21.1297Z"
                fill="#8F4423"
            />
        </g>
        <defs>
            <radialGradient
                id="paint0_radial_1674_36818"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(29.1886 1.26916) scale(29.8734 29.7362)"
            >
                <stop offset="0.01" stop-color="#DA7D28" />
                <stop offset="1" stop-color="#EB5D55" />
            </radialGradient>
            <radialGradient
                id="paint1_radial_1674_36818"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(34.6233 -2.7806) scale(41.3291 41.1393)"
            >
                <stop offset="0.01" stop-color="#DA7D28" />
                <stop offset="1" stop-color="#EB5D55" />
            </radialGradient>
            <linearGradient
                id="paint2_linear_1674_36818"
                x1="36.4077"
                y1="-5.70749"
                x2="17.7785"
                y2="17.3778"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F0768C" />
                <stop offset="0.19" stop-color="#EE7184" />
                <stop offset="0.41" stop-color="#E6636F" />
                <stop offset="0.66" stop-color="#DB504C" />
                <stop offset="0.93" stop-color="#C54931" />
                <stop offset="1" stop-color="#B84A2D" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_1674_36818"
                x1="6.57628"
                y1="31.5471"
                x2="22.8608"
                y2="11.0415"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#673F25" />
                <stop offset="1" stop-color="#8E5127" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_1674_36818"
                x1="19.1279"
                y1="17.9703"
                x2="19.1279"
                y2="24.1821"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FF98A2" />
                <stop offset="0.23" stop-color="#FFB3BB" />
                <stop offset="0.51" stop-color="#FFCED3" />
                <stop offset="0.77" stop-color="#FFDDE3" />
                <stop offset="1" stop-color="#FFE3E9" />
            </linearGradient>
            <radialGradient
                id="paint5_radial_1674_36818"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(28.5444 2.57112) scale(30.2728 30.1338)"
            >
                <stop offset="0.01" stop-color="#DA7D28" />
                <stop offset="1" stop-color="#EB5D55" />
            </radialGradient>
            <radialGradient
                id="paint6_radial_1674_36818"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(28.544 2.57116) scale(30.2728 30.1338)"
            >
                <stop offset="0.01" stop-color="#DA7D28" />
                <stop offset="1" stop-color="#EB5D55" />
            </radialGradient>
            <linearGradient
                id="paint7_linear_1674_36818"
                x1="36.1173"
                y1="-4.64035"
                x2="19.2512"
                y2="20.8898"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F0768C" />
                <stop offset="0.19" stop-color="#EE7184" />
                <stop offset="0.41" stop-color="#E6636F" />
                <stop offset="0.66" stop-color="#DB504C" />
                <stop offset="0.93" stop-color="#C54931" />
                <stop offset="1" stop-color="#B84A2D" />
            </linearGradient>
            <linearGradient
                id="paint8_linear_1674_36818"
                x1="40.5572"
                y1="-1.70887"
                x2="23.6887"
                y2="23.8214"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F0768C" />
                <stop offset="0.19" stop-color="#EE7184" />
                <stop offset="0.41" stop-color="#E6636F" />
                <stop offset="0.66" stop-color="#DB504C" />
                <stop offset="0.93" stop-color="#C54931" />
                <stop offset="1" stop-color="#B84A2D" />
            </linearGradient>
            <linearGradient
                id="paint9_linear_1674_36818"
                x1="3.01969"
                y1="28.6296"
                x2="19.3084"
                y2="9.27623"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#673F25" />
                <stop offset="1" stop-color="#8E5127" />
            </linearGradient>
            <linearGradient
                id="paint10_linear_1674_36818"
                x1="6.04063"
                y1="31.1706"
                x2="22.3317"
                y2="11.8173"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#673F25" />
                <stop offset="1" stop-color="#8E5127" />
            </linearGradient>
        </defs>
    </svg>
</template>
